import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/actions/profileActions';
import { getAllNotificationsCount } from '../../redux/actions/notificationsActions';
import { SMALL_SCREEN_WIDTH, SEPARATOR } from '../Widgets/utils/catalogs';
import logo from '../../static/logo.png';
import { Role } from '../../shared/authorization';
import './Header.scss';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getAllNotificationsCount: () => dispatch(getAllNotificationsCount())
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class Header
 * @extends {Component}
 */

class Header extends Component {
  static propTypes = {
    profile: PropTypes.instanceOf(Immutable.Map).isRequired,
    logout: PropTypes.func.isRequired,
    getAllNotificationsCount: PropTypes.func.isRequired,
    notificationsReducer: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  constructor(...args) {
    super(...args);

    this.state = {
      width: window.innerWidth,
      reskin: false,
    };
    this.intervalId = null; // Initialize interval ID
  }

  componentDidMount() {
    // Start the interval when the component mounts
    this.intervalId = setInterval(() => {
      if (this.onToggleCheckBoxFound()) {
        this.onToggleCheckBoxFound();
      }
    }, 2000);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.props.getAllNotificationsCount();
  }

  componentWillUnmount() {
    // Clear the interval when the component is about to unmount
    clearInterval(this.intervalId);
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  onChangeSkin = () => {
    this.setState({ reskin: !this.state.reskin }, () => {
      this.switchReskin();
    });
  };

  switchReskin = () => {
    if (this.state.reskin) {
      document.documentElement.style.setProperty('--skin', 'new');
      this.setThemeCookie('skin', 'new');
    } else {
      this.setThemeCookie('skin', 'old');
    }
    window.location.reload();
  }

  setThemeCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + "; path=/";
  }

  getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  onToggleCheckBoxFound = () => {
    /* if (this.state.reskin) { */
    const reSkinValue = this.getCookie('skin');
    if (reSkinValue == 'new') {
      this.setState({ reskin: true });
    } else {
      this.setState({ reskin: false });
    }
  }

  isMobile = () => {
    const { width } = this.state;
    return width <= SMALL_SCREEN_WIDTH;
  };

  renderAllNotificationsCount = () => {
    const count = this.props.notificationsReducer.get('count');
    if (count < 1) {
      return null;
    }
    return (<Badge className="btn-primary" style={{ marginLeft: 2 }}>{count}</Badge>);
  };

  renderMobile = () => {
    const staqAppUrl = this.props.profile.getIn(['tokens', 'staqAppUrl']);
    const csrf = this.props.profile.getIn(['tokens', 'csrf']);
    return (
      <div className="Header">
        <Navbar bg="white" expand="md">
          <Navbar.Brand>
            <Link to="/home">
              <img
                src={logo}
                width="100"
                className="d-inline-block align-top"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Item>
                <Nav.Link href={`${staqAppUrl}/dashboards`}>Dashboard</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to="/home">
                  <Nav.Link active className="underline-nav">
                    Intelligence
                    {this.renderAllNotificationsCount()}
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
              <Nav.Item>
                <LinkContainer to="#">
                  <Nav.Link onSelect={this.onSelectSignOut}>
                    Sign Out
                    <form
                      action={`${staqAppUrl}/users/sign_out`}
                      method="POST"
                      ref="signOutForm"
                    >
                      <input type="hidden" name="_method" value="delete" />
                      <input
                        type="hidden"
                        name="authenticity_token"
                        value={csrf}
                      />
                    </form>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  };

  onSelectSignOut = () => {
    this.props.logout();
    this.refs.signOutForm.submit();
  };

  reskinToggle(reskin) {
    return reskin ? (
      <div aria-label="First group" className="mr-2 btn-group">
        <div className="Reskin-ToggleOn" onClick={this.onChangeSkin}>
          <i className="fa fa-toggle-on"></i>
        </div>
      </div>
    ) : (
      <div aria-label="First group" className="mr-2 btn-group">
        <div className="Reskin-ToggleOff" onClick={this.onChangeSkin}>
          <i className="fa fa-toggle-off"></i>
        </div>
      </div>
    );
  }

  render() {
    const staqAppUrl = this.props.profile.getIn(['tokens', 'staqAppUrl']);
    const csrf = this.props.profile.getIn(['tokens', 'csrf']);
    const role = this.props.profile.getIn(['role']);
    const { reskin } = this.state;
    if (this.isMobile()) {
      return this.renderMobile();
    }
    return (
      <div className="Header">
        <Navbar bg="white" expand="md">
          <Navbar.Brand>
            <Link to="/home">
              <img
                src={logo}
                width="100"
                className="d-inline-block align-top"
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link
                className="underline-nav"
                href={`${staqAppUrl}/dashboards`}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                className="underline-nav"
                href={`${staqAppUrl}/report_manager`}
              >
                Reports
              </Nav.Link>
              <Nav.Link
                className="underline-nav"
                href={`${staqAppUrl}/source_manager`}
              >
                Sources
              </Nav.Link>
              <Nav.Link
                className="underline-nav"
                href={`${staqAppUrl}/connection_manager`}
              >
                Connections
              </Nav.Link>
              <LinkContainer to="/home">
                <Nav.Link className="underline-nav" active>
                  Intelligence
                  {this.renderAllNotificationsCount()}
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className="justify-content-end">
              {role === Role.Admin && <Nav.Item className="support">
                <Nav.Link href="https://operative.my.site.com/OPERATE">
                  Support
                  {SEPARATOR}
                  <i className="fa fa-question-circle"></i>
                </Nav.Link>
              </Nav.Item>}
              <Nav.Item className="themeText">
                <Nav.Link>
                  New Theme
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                {this.reskinToggle(reskin)}
              </Nav.Item>
              <NavDropdown
                title={this.props.profile.get('name')}
                alignRight
                id="dropdown-menu-align-right"
              >
                <NavDropdown.Item href={`${staqAppUrl}/dashboards`}>
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href={`${staqAppUrl}/report_builder/reports/new`}
                >
                  Add New Report
                </NavDropdown.Item>
                <NavDropdown.Item href={`${staqAppUrl}/report_manager`}>
                  Report Manager
                </NavDropdown.Item>
                <NavDropdown.Item href={`${staqAppUrl}/source_manager`}>
                  Source Manager
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href={`${staqAppUrl}/connection_manager`}>
                  Connection Manager
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href={`${staqAppUrl}/account_manager`}>
                  Account Manager
                </NavDropdown.Item>
                <NavDropdown.Item onSelect={this.onSelectSignOut}>
                  Sign Out
                  <form
                    action={`${staqAppUrl}/users/sign_out`}
                    method="POST"
                    ref="signOutForm"
                  >
                    <input type="hidden" name="_method" value="delete" />
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={csrf}
                    />
                  </form>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);